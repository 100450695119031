import { Splide } from "@splidejs/splide";

export const eventSlider = () => {
  if (document.querySelector(".js-event-slider") !== null) {
    const splide = new Splide(".js-event-slider", {
      type: "loop",
      gap: "3.2rem",
      perMove: 1,
      perPage: 3,
      pagination: false,
      rewind: false,
      breakpoints: {
        1280: {
          perPage: 2.5,
        },
        1024: {
          perPage: 3,
          gap: "1.5rem",
        },
        900: {
          perPage: 2.5,
        },
        767: {
          perPage: 2,
        },
        599: {
          perPage: 1,
        },
      },
    });

    splide.mount();
  }
};
