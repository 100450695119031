import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

export const tagSliders = () => {
  if (document.querySelector(".js-tag-slider-top") === null) return;

  const tagSliderTop = new Splide(".js-tag-slider-top", {
    type: "loop",
    gap: "2.3rem",
    autoWidth: true,
    arrows: false,
    drag: false,
    pagination: false,
    autoScroll: {
      pauseOnHover: false,
      speed: 0.5,
    },
    breakpoints: {
      1023: {
        gap: "1rem",
      },
    },
  });

  const tagSliderBottom = new Splide(".js-tag-slider-bottom", {
    type: "loop",
    gap: "2.3rem",
    autoWidth: true,
    arrows: false,
    drag: false,
    pagination: false,
    direction: "rtl",
    autoScroll: {
      pauseOnHover: false,
      speed: 0.5,
    },
    breakpoints: {
      1023: {
        gap: "1rem",
      },
    },
  });

  tagSliderTop.mount({ AutoScroll });
  tagSliderBottom.mount({ AutoScroll });
};
