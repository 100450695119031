import $ from "jquery";
import { homeMvSlider } from "./modules/homeMvSlider";
import { textSlideUpInview } from "./modules/textSlideUpInview";
import { lenisInertiaScroll } from "./modules/lenisInertiaScroll";
import { caseSlider } from "./modules/caseSlider";
import { imageParallax } from "./modules/imageParallax";
import { tagSliders } from "./modules/tagSliders";
import { inview } from "./modules/inview";
import { columnSlider } from "./modules/columnSlider";
import { featureStickySlides } from "./modules/home/featureStickySlides";
import { overlapSections } from "./modules/home/overlapSections";
import { scaleBoxOnScroll } from "./modules/home/scaleBoxOnScroll";
import { headerSubNav } from "./modules/headerSubNav";
import { headerTheme } from "./modules/headerTheme";
import { initSearchFormByAjax } from "./modules/searchFormByAjax.js";
import { paymentStickySlides } from "./modules/officeconstruction/paymentStickySlides";
import { inviewCustom } from "./modules/inviewCustom";
import { inviewOnceSessionStorage } from "./modules/inviewOnceSessionStorage.js";
import { eventSlider } from "./modules/eventSlider.js";
import { beforeAfterSticky } from "./modules/liveoffice/beforeAfterSticky.js";
import { imageParallaxUpDown } from "./modules/brands/imageParallaxUpDown.js";
import { anchorSmoothScrollOnLoad } from "./modules/anchorSmoothScrollOnLoad.js";
import { hoverAnimationOnElements } from "./modules/document/hoverAnimationOnElements.js";
import { eventThumbsFollowCursor } from "./modules/home/eventThumbsFollowCursor.js";
import { serviceThumbsFollowCursor } from "./modules/home/serviceThumbsFollowCursor.js";
import { hamburgerMenu } from "./modules/hamburgerMenu.js";
import { formrunCustomValidation } from "./modules/document/formrunCustomValidation.js";
import { selectPlaceholder } from "./modules/document/selectPlaceholder.js";
import { inviewLetterAnimation } from "./modules/inviewLetterAnimation.js";
import { mvMouseMove } from "./modules/museum/mvMouseMove.js";
import { googleMap } from "./modules/museum/googleMap.js";

$(function () {
  lenisInertiaScroll(); // 実装中は邪魔なのでOFF

  // common
  headerSubNav();
  headerTheme();
  hamburgerMenu();
  inviewOnceSessionStorage(".js-projects-inview-once", "projects-inview");
  inviewOnceSessionStorage(".js-brands-inview-once", "brands-inview");
  inviewOnceSessionStorage(".js-event-inview-once", "event-inview");
  inviewOnceSessionStorage(".js-column-inview-once", "column-inview");
  anchorSmoothScrollOnLoad();

  // home
  homeMvSlider();
  featureStickySlides();
  scaleBoxOnScroll();
  overlapSections();
  caseSlider();
  imageParallax();
  tagSliders();
  columnSlider();
  eventSlider();
  eventThumbsFollowCursor();
  serviceThumbsFollowCursor();

  // officeconstruction
  paymentStickySlides();

  // liveoffice
  beforeAfterSticky();

  // brands
  imageParallaxUpDown();

  initSearchFormByAjax();

  // document
  hoverAnimationOnElements();
  formrunCustomValidation();
  selectPlaceholder();

  // museum
  if ($(".js-page-museum").length) {
    mvMouseMove();
    // googleMap();
  }

  // common
  inview();
  inviewCustom();
  textSlideUpInview();
  inviewLetterAnimation();
});
