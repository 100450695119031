import $ from "jquery";

export const hoverAnimationOnElements = () => {
  const els = $(".js-hover-anim-els");

  els.each(function () {
    const targets = $(this).find(".js-hover-anim-el");

    targets.on("mouseenter", function () {
      targets.addClass("is-hover");
    });
    targets.on("mouseleave", function () {
      targets.removeClass("is-hover");
    });
  });
};
