import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { mediaQueries, matchMediaQuery } from "../../utils/matchMediaQuery";

gsap.registerPlugin(ScrollTrigger);

export const imageParallaxUpDown = () => {
  if (
    document.querySelector(".js-image-parallax-up") === null &&
    document.querySelector(".js-image-parallax-down") === null
  )
    return;

  // タブレット以下の場合は処理を終了
  if (matchMediaQuery(mediaQueries.tab)) return;

  const imagesToUp = gsap.utils.toArray(".js-image-parallax-up");
  const imagesToDown = gsap.utils.toArray(".js-image-parallax-down");

  imagesToUp.forEach((image) => {
    const dataYPercent = image.getAttribute("data-parallax-percentage");

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: image,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
        pin: false,
      },
    });

    tl.fromTo(
      gsap.utils.selector(image)("img"),
      {
        ease: "power1.in",
      },
      {
        yPercent: dataYPercent !== null ? dataYPercent : -10,
        ease: "power1.in",
      }
    );
  });

  imagesToDown.forEach((image) => {
    const dataYPercent = image.getAttribute("data-parallax-percentage");

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: image,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
        pin: false,
      },
    });

    tl.fromTo(
      gsap.utils.selector(image)("img"),
      {
        ease: "power1.in",
      },
      {
        yPercent: dataYPercent !== null ? dataYPercent : 10,
        ease: "power1.in",
      }
    );
  });
};
