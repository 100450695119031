import { gsap } from "gsap";

export const mvMouseMove = () => {
  const mv = document.querySelector(".js-mv");
  const images = document.querySelectorAll(".js-mv-image");

  mv.addEventListener("mousemove", (e) => {
    const rect = mv.getBoundingClientRect();
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;

    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    const offsetX = (centerX - mouseX) * 0.05; // Adjust multiplier for sensitivity
    const offsetY = (centerY - mouseY) * 0.05;

    images.forEach((image) => {
      // image.style.transform = `translate(calc(-50% + ${moveX}px), calc(-50% + ${moveY}px)) scale(1.2)`;

      // setTimeout(() => {
      //   image.style.transition = "transform 0s";
      // }, 500);

      gsap.to(image, {
        x: offsetX,
        y: offsetY,
        duration: 2,
        ease: "power3.out",
      });
    });
  });

  mv.addEventListener("mouseleave", () => {
    images.forEach((image) => {
      // image.style.transition = "transform 0.3s";
      // image.style.transform = "translate(-50%, -50%) scale(1.2)";

      gsap.to(image, {
        x: 0,
        y: 0,
        duration: 2,
        ease: "power3.out",
      });
    });
  });
};
