import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export const imageParallax = () => {
  gsap.registerPlugin(ScrollTrigger);

  const images = gsap.utils.toArray(".js-image-parallax");

  images.forEach((image) => {
    const scaleValue = image.getAttribute("data-image-parallax-scale") || 1.2;
    const yPercentValue = ((scaleValue - 1) / 2) * 100 || 10;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: image,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
        pin: false,
      },
    });

    tl.fromTo(
      gsap.utils.selector(image)("img"),
      {
        scale: scaleValue,
        yPercent: yPercentValue * -1,
        ease: "power1.in",
      },
      {
        scale: scaleValue,
        yPercent: yPercentValue,
        ease: "power1.in",
      }
    );
  });
};
