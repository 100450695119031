import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const beforeAfterSticky = () => {
  if (document.querySelector(".js-before-after-sticky") === null) return;

  const trigger = document.querySelector(".js-before-after-sticky");

  // const beforeImages = gsap.utils.toArray(".js-before-img");
  const beforeImages = document.querySelectorAll(".js-before-img");
  const afterImages = document.querySelectorAll(".js-after-img");

  const beforeHead = document.querySelector(".js-before-head");
  const afterHead = document.querySelector(".js-after-head");

  const beforeMask01 = document.querySelector(".js-before-mask-01");
  const beforeMask02 = document.querySelector(".js-before-mask-02");

  beforeImages.forEach((image) => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: trigger,
        scrub: true,
        start: "top top",
        end: "+=300%",
        onUpdate: (self) => {
          const progress = self.progress;
          console.log(progress);

          if (progress < 0.65) {
            beforeHead.classList.add("is-visible");
            afterHead.classList.remove("is-visible");
          } else {
            beforeHead.classList.remove("is-visible");
            afterHead.classList.add("is-visible");
          }
        },
      },
    });

    tl.to(image, {
      y: "120%",
    });

    tl.to(
      beforeMask01,
      {
        y: "-120%",
      },
      "<"
    );
    tl.to(
      beforeMask02,
      {
        y: "-120%",
      },
      "<"
    );
  });

  afterImages.forEach((image) => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: trigger,
        scrub: true,
        start: "top top",
        end: "+=250%",
      },
    });

    tl.fromTo(
      image,
      {
        y: "10%",
      },
      {
        y: "0%",
      }
    );
  });
};
