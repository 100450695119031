export const inviewLetterAnimation = () => {
  const target = document.querySelectorAll(".js-inview-letter-animation");

  target.forEach((element) => {
    const paragraphs = element.querySelectorAll(".js-inview-letter-animation-group");

    var totalDelay = 0; // 総遅延時間を保持

    paragraphs.forEach(function (paragraph) {
      // テキストノードとbrタグを含む子ノードをすべて取得
      var childNodes = Array.from(paragraph.childNodes);
      var newContent = "";
      // data-letter-animation-duration属性を取得
      const duration = paragraph.getAttribute("data-letter-animation-duration") || 0.01;

      childNodes.forEach(function (node) {
        if (node.nodeType === Node.TEXT_NODE) {
          // テキストノードを分割して、spanタグで囲む
          var textParts = node.textContent.split("");
          for (let i = 0; i < textParts.length; i++) {
            var char = textParts[i];
            var charDisplay = char === " " ? "&nbsp;" : char;
            if (char === "、" || char === "。") {
              // 句読点の場合、直前の1文字とまとめてspanで囲う
              var prevChar = textParts[i - 1] === " " ? "&nbsp;" : textParts[i - 1];
              newContent =
                newContent.slice(0, -1) +
                '<span style="transition-delay:' +
                (totalDelay - 1) * Number(duration) +
                's;">' +
                charDisplay +
                "</span>";
            } else {
              newContent +=
                '<span style="transition-delay:' + totalDelay * Number(duration) + 's;">' + charDisplay + "</span>";
              totalDelay++;
            }
          }
        } else if (node.nodeType === Node.ELEMENT_NODE && node.tagName === "BR") {
          // brタグはそのまま追加
          let brTag = "<br";
          if (node.classList.length > 0) {
            brTag += ' class="' + node.className + '"';
          }
          brTag += ">";
          newContent += brTag;
        }
      });

      // pタグの内容を新しいコンテンツに置き換える
      paragraph.innerHTML = newContent;
    });
  });
};
