import gsap from "gsap";

export const hamburgerMenu = () => {
  // if the page has hamburger btn and hamburger menu
  if (!document.querySelector("#hamburger-btn")) {
    return;
  }

  const btn = document.querySelector("#hamburger-btn");
  const menuBg = document.querySelector(".js-hamburger-menu-bg");
  const menuSeparator = document.querySelector(".js-hamburger-menu-separator");
  const headerOverlay = document.querySelector(".js-header-overlay");

  // メニューを開く関数
  const openMenuHandler = () => {
    document.body.classList.add("is-hamburger-menu-open");
    btn.classList.add("js-hamburger-menu-open");

    btn.style.pointerEvents = "none";
    headerOverlay.style.pointerEvents = "none";

    const tl = gsap.timeline();
    tl.to(menuBg, {
      duration: 0.6,
      ease: "power3.out",
      scale: 1,
      onComplete: function () {
        btn.style.pointerEvents = "auto";
        headerOverlay.style.pointerEvents = "auto";
      },
    })

      .to(
        ".hamburger-menu__link, .hamburger-menu__head, .hamburger-menu__sub-link, .hamburger-menu__btn-group",
        {
          opacity: 1,
          duration: 0.3,
          stagger: {
            // wrap advanced options in an object
            each: 0.02,
            ease: "power1.in",
          },
        },
        "-=0.3"
      )
      .to(
        menuSeparator,
        {
          scale: 1,
          duration: 0.6,
          ease: "power3.out",
        },
        "-=0.3"
      );
  };

  // メニューを閉じる関数
  const closeMenuHandler = () => {
    document.body.classList.remove("is-hamburger-menu-open");
    btn.classList.remove("js-hamburger-menu-open");

    btn.style.pointerEvents = "none";
    headerOverlay.style.pointerEvents = "none";

    const tl = gsap.timeline();
    tl.to(menuSeparator, {
      scale: 0,
      duration: 0.6,
      ease: "power3.out",
      onComplete: function () {
        btn.style.pointerEvents = "auto";
        headerOverlay.style.pointerEvents = "none";
      },
    })
      .to(
        ".hamburger-menu__link, .hamburger-menu__head, .hamburger-menu__sub-link, .hamburger-menu__btn-group",
        {
          opacity: 0,
          duration: 0.3,
        },
        "0"
      )
      .to(menuBg, {
        duration: 0.6,
        ease: "power3.out",
        scale: 0,
      });
  };

  btn.addEventListener("click", () => {
    // メニューを閉じる
    if (btn.classList.contains("js-hamburger-menu-open")) {
      closeMenuHandler();
    }
    // メニューを開く
    else {
      openMenuHandler();
    }
  });

  headerOverlay.addEventListener("click", () => {
    closeMenuHandler();
  });
};
