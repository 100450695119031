import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const paymentStickySlides = () => {
  if (document.querySelector(".js-payment-sticky") === null) return;

  let mm = gsap.matchMedia();

  mm.add("(min-width: 1024px)", () => {
    const trigger = document.querySelector(".js-payment-sticky");

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: trigger,
        scrub: true,
        start: "top top",
        end: "+=450%",
        onUpdate: (self) => {
          const progress = self.progress;
          const currentActiveIndex = Math.round(progress * 3);
          trigger.setAttribute("data-active-slide", currentActiveIndex);

          if (currentActiveIndex >= 3) {
            trigger.setAttribute("data-active-slide", 2);
          }
        },
      },
    });
  });
};
