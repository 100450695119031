import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import $ from "jquery";

gsap.registerPlugin(ScrollTrigger);

export const overlapSections = () => {
  // let panels = gsap.utils.toArray(".js-overlap-section");
  // // we'll create a ScrollTrigger for each panel just to track when each panel's top hits the top of the viewport (we only need this for snapping)
  // let tops = panels.map((panel) => ScrollTrigger.create({ trigger: panel, start: "top top" }));

  // panels.forEach((panel, i) => {
  //   ScrollTrigger.create({
  //     trigger: panel,
  //     // start: () => (panel.offsetHeight < window.innerHeight ? "top top" : "bottom bottom"), // if it's shorter than the viewport, we prefer to pin it at the top
  //     start: () => (panel.offsetHeight < window.innerHeight ? "top top" : "bottom 50%"),
  //     pin: true,
  //     pinSpacing: false,
  //   });
  // });

  const stickyPage = (() => {
    // if (navigator.userAgent.indexOf("iPhone") > 0) {
    //   $(".js-overlap-section").each(function (i) {
    //     $(this).css("position", "relative");
    //   });
    // } else {
    $(window).on("load scroll resize", function () {
      var length = $(".js-overlap-section").length;
      $(".js-overlap-section").each(function (i) {
        var setPos = $(this).outerHeight() - $(window).height() / 2;
        $(this).css("top", -setPos);
      });
    });
    // }
  })();
};
