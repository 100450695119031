import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const headerTheme = () => {
  const headerTriggers = document.querySelectorAll(".js-header-theme-trigger");

  const body = document.querySelector("body");

  headerTriggers.forEach((headerTrigger) => {
    ScrollTrigger.create({
      trigger: headerTrigger,
      start: "top-=1px top",
      end: "bottom top",
      onEnter: () => {
        body.classList.add("is-header-white");
      },
      onLeaveBack: () => {
        body.classList.remove("is-header-white");
      },
      onEnterBack: () => {
        body.classList.add("is-header-white");
      },
      onLeave: () => {
        body.classList.remove("is-header-white");
      },
    });
  });
};
