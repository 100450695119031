import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { imageParallax } from "./imageParallax";

gsap.registerPlugin(ScrollTrigger);

const initSearchFormByAjax = () => {
  const $form = document.querySelector("#js-search-form-input");
  const resultTarget = "#js-search-form-result";
  if (!$form) return;

  // 同nameのcheckboxが複数ある場合、一つしか選択できないようにする
  const checkboxes = [...$form.querySelectorAll('input[type="checkbox"]')];
  checkboxes.forEach((checkbox) => {
    checkbox.addEventListener("change", () => {
      checkboxes.forEach((cb) => {
        if (cb !== checkbox && cb.name === checkbox.name) {
          cb.checked = false;
        }
      });
    });
  });

  // フォームの変更時にイベントを発火
  $form.addEventListener("change", (e) => {
    e.preventDefault();

    // 透明にする
    gsap.to(resultTarget, {
      alpha: 0,
      duration: 0.3,
    });

    // 透明にしてから記事を取得
    setTimeout(() => {
      // 条件が変更されてtagが表示されている場合は非表示にして、checkedを外す
      const tagsItem = document.querySelector("#js-search-form-tags");
      tagsItem && tagsItem.classList.add("is-hide");
      const tags = [...document.querySelectorAll('input[name="projects-tag"]')];
      tags.forEach((tag) => (tag.checked = false));

      //　GETのURLを取得
      const baseUrl = $form.getAttribute("action");
      const data = new FormData($form);
      const queryParams = new URLSearchParams(data);
      const url = `${baseUrl}?${queryParams.toString()}`;

      // URLからコンテンツを取得して差し替える
      fetch(url, {
        method: "GET",
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`);
          }

          return response.text();
        })
        .then((text) => {
          // textをHTML要素に変換
          const parser = new DOMParser();
          const doc = parser.parseFromString(text, "text/html");
          const $result = doc.querySelector(resultTarget);

          // textからresultTarget内の要素を取得
          document.querySelector(resultTarget).innerHTML = $result.innerHTML;

          // URLを変更
          history.replaceState(null, null, url);

          // 高さが変わる場合にGSAPのScrollTriggerを更新
          ScrollTrigger.refresh();

          // 画像遷移後パララックスが効くようにする
          imageParallax();

          // 透明状態から表示
          setTimeout(() => {
            gsap.to(resultTarget, {
              alpha: 1,
              duration: 0.5,
            });
          }, 500);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }, 300);
  });
};

export { initSearchFormByAjax };
