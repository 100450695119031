import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const scaleBoxOnScroll = () => {
  let els = gsap.utils.toArray(".js-scale-box");

  els.forEach((el) => {
    gsap.fromTo(
      el,
      {
        scale: 0.96,
      },
      {
        scale: 1,
        scrollTrigger: {
          trigger: el,
          start: "top 80%",
          end: "+=300",
          scrub: 2,
        },
      }
    );
  });
};
