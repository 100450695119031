export const formrunCustomValidation = () => {
  if (document.querySelector(".js-custom-validation") === null) return;

  const targets = document.querySelectorAll(".js-custom-validation");

  targets.forEach((target) => {
    const input = target.querySelector("input");
    const errorMessage = target.querySelector(".js-custom-error-message");

    input.addEventListener("input", () => {
      if (input.value !== "") {
        errorMessage.classList.add("has-value");
      } else {
        errorMessage.classList.remove("has-value");
      }
    });
  });
};
