import Lenis from "@studio-freight/lenis";
import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";

export const lenisInertiaScroll = () => {
  const lenis = new Lenis();

  // lenis.on("scroll", (e) => {
  //   console.log(e);
  // });

  lenis.on("scroll", ScrollTrigger.update);

  gsap.ticker.add((time) => {
    lenis.raf(time * 1000);
  });

  gsap.ticker.lagSmoothing(0);

  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();
      lenis.scrollTo(this.getAttribute("href"), {
        // offset: document.querySelector(".js-header-content").clientHeight * -1 - 20,
        offset: document.querySelector(".js-header-content").clientHeight * -1, // museumページのために-20を消した
        immediate: false,
        duration: 1.5,
        easing: (x) => {
          return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;
        },
      });
    });
  });
};
