export const selectPlaceholder = () => {
  if (document.querySelector(".js-select-placeholder") === null) return;

  const selectEls = document.querySelectorAll(".js-select-placeholder");

  selectEls.forEach((el) => {
    el.classList.add("is-empty");

    el.addEventListener("change", () => {
      if (el.value === "") {
        el.classList.add("is-empty");
      } else {
        el.classList.remove("is-empty");
      }
    });
  });
};
