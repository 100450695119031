import Splide from "@splidejs/splide";

export const caseSlider = () => {
  var elms = document.getElementsByClassName("js-case-slider");

  for (var i = 0; i < elms.length; i++) {
    new Splide(elms[i], {
      type: "loop",
      pagination: false,
    }).mount();
  }
};
