import $ from "jquery";

export const headerSubNav = () => {
  const navLink = $(".js-header-sub-nav-trigger");

  navLink
    .on("mouseover", function () {
      $("body").addClass("is-header-sub-nav-active");
      $(this).addClass("is-active");
      $(this).find(".js-header-sub-nav").addClass("is-active");
    })
    .on("mouseout", function () {
      $("body").removeClass("is-header-sub-nav-active");
      $(this).removeClass("is-active");
      $(this).find(".js-header-sub-nav").removeClass("is-active");
    });
};
