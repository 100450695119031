import Splide from "@splidejs/splide";

export const homeMvSlider = () => {
  if (document.querySelector(".js-mv-slider") === null) return;

  const activeIndexEl = document.querySelector(".js-mv-slider-active-count");
  const totalCountEl = document.querySelector(".js-mv-slider-total-count");
  const progressBarEl = document.querySelector(".js-mv-slider-progress-bar");

  const slider = new Splide(".js-mv-slider", {
    type: "fade",
    rewind: true, // fadeとloopを両立させるために必要
    autoplay: true,
    interval: 3500,
    pauseOnHover: false,
    speed: 2000,
    arrows: false,
    pagination: false,
    drag: false,
  });

  slider.on("autoplay:playing", function (rate) {
    // console.log(rate); // 0-1
    progressBarEl.style.height = rate * 100 + "%";
  });

  slider.on("active", function () {
    totalCountEl.textContent = slider.length;
    activeIndexEl.textContent = slider.index + 1;
  });

  slider.mount();
};
