import $ from "jquery";

export const anchorSmoothScrollOnLoad = () => {
  // URLハッシュがあるのは、お役立ち資料のパンくずリストでカテゴリーをクリックした時のみ（お役立ち資料はカテゴリ別記事一覧がないので、1つのページに全カテゴリが集まってる→該当セクションにスクロールさせる必要がある）
  var urlHash = location.hash;
  if (urlHash) {
    $("body,html").stop().scrollTop(0);
    setTimeout(function () {
      var target = $(urlHash);
      var headerHeight = $(".js-header-content").innerHeight();
      var position = target.offset().top - headerHeight - 5;
      $("body,html").stop().animate({ scrollTop: position }, 500);
    }, 500);
  }
};
