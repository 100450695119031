export const inviewCustom = () => {
  const els = document.querySelectorAll(".inview-custom");

  const cb = function (entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // 以下の条件でバグる
        // 1. js-overlap-sectionの中にある
        // 2. ページロード完了時に既に画面内に入っている
        // これを回避するためにsetTimeoutで遅延させる
        setTimeout(() => {
          entry.target.classList.add("is-inview");
          observer.unobserve(entry.target);
        }, 100);
      }
    });
  };

  const options = {
    rootMargin: "-15% 0px",
  };

  const io = new IntersectionObserver(cb, options);

  els.forEach((el) => {
    io.observe(el);
  });
};
