export const inviewOnceSessionStorage = (selectors, sessionName) => {
  if (document.querySelector(selectors) === null) return;

  const els = document.querySelectorAll(selectors);
  const firstVisitFlag = sessionStorage.getItem(sessionName);

  // 初回
  if (!firstVisitFlag) {
    sessionStorage.setItem(sessionName, true);
  }
  // 2回目以降
  else {
    els.forEach((el) => {
      // is-inviewを最初から付与してアニメーションを発火させない
      el.classList.add("is-inview");
    });
  }
};
