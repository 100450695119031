import gsap from "gsap";
import { mediaQueries, matchMediaQuery } from "../../utils/matchMediaQuery";

export const eventThumbsFollowCursor = () => {
  if (document.querySelector(".js-event-thumbs-follow-cursor") === null) return;

  // タブレット以下の場合は処理を終了
  if (matchMediaQuery(mediaQueries.tab)) return;

  const cursor = document.getElementById("event-cursor");
  const follower = document.getElementById("event-follower");
  const pos = { x: 0, y: 0 }; // カーソル要素の座標
  const mouse = { x: pos.x, y: pos.y }; // マウスカーソルの座標
  const speed = 0.8; // 0.01〜1 数値が大きほど早い

  const area = document.querySelector(".js-event-thumbs-follow-cursor");

  const thumbs = document.querySelectorAll(".js-event-thumb");

  // カーソルの座標を設定する
  var cursorSetX = gsap.quickSetter(cursor, "x", "px");
  var cursorSetY = gsap.quickSetter(cursor, "y", "px");

  // 遅延するカーソルの座標を設定する
  var followerSetX = gsap.quickSetter(follower, "x", "px");
  var followerSetY = gsap.quickSetter(follower, "y", "px");

  // マウスカーソル座標を取得する
  document.addEventListener("mousemove", function (event) {
    mouse.x = event.pageX;
    mouse.y = event.pageY;
  });

  // レンダリングする
  gsap.ticker.add(function () {
    var dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

    pos.x += (mouse.x - pos.x) * dt;
    pos.y += (mouse.y - pos.y) * dt;
    cursorSetX(pos.x);
    cursorSetY(pos.y);
    followerSetX(pos.x);
    followerSetY(pos.y);
  });

  area.addEventListener("mouseenter", () => {
    gsap.to(cursor, { autoAlpha: 1 });
    gsap.to(follower, { autoAlpha: 1 });
  });

  area.addEventListener("mouseleave", () => {
    gsap.to(cursor, { autoAlpha: 0 });
    gsap.to(follower, { autoAlpha: 0 });
  });

  thumbs.forEach((thumb, i) => {
    thumb.addEventListener("mouseenter", () => {
      const activeImages = document.querySelectorAll(".event-follower__active-img.is-active");

      activeImages.forEach((activeImage) => {
        activeImage.classList.remove("is-active");

        setTimeout(() => {
          activeImage.remove();
        }, 1000);
      });

      const clonedImg = follower.querySelectorAll(".js-event-follower-img img")[i].cloneNode(true);
      const div = document.createElement("div");
      div.classList.add("event-follower__active-img");
      div.classList.add("is-active");
      div.appendChild(clonedImg);
      follower.prepend(div);
    });
    thumb.addEventListener("mouseleave", () => {
      follower.querySelectorAll(".js-event-follower-img")[i].classList.remove("is-hover");
    });
  });
};
